<template>
  <div class="downloads -m-4 flex flex-row flex-wrap">
    <div class="download p-4 w-full sm:w-1/2" v-for="(file, index) in renamedFiles" :key="`file${index}`">
      <a :href="file.url" target="_blank" class="overflow-hidden flex flex-row w-full items-center border border-grey-100" @click="track(file.name)">
        <div class="icon bg-primary-500 p-4 md:p-8 flex-shrink-0">
          <img src="@/assets/icon-file.png" alt="" class="block w-8">
        </div>
        <div class="download-inner px-4 py-2 md:px-8 overflow-ellipsis">{{ file.friendlyName }}</div>
      </a>
    </div>
  </div>
</template>

<script>
import Analytics from '@/analytics'
export default {
  name: 'Downloads',
  props: {
    files: {
      type: Array,
      default () {
        return []
      }
    },
    wineryId: {
      type: String,
      default: null
    },
    productId: {
      type: String,
      default: null
    },
    regionId: {
      type: String,
      default: null
    }
  },
  computed: {
    renamedFiles () {
      return this.files.map((item) => {
        return {
          ...item,
          friendlyName: item.name.indexOf('_') !== -1 ? item.name.substring(item.name.indexOf('_') + 1) : item.name
        }
      })
    }
  },
  methods: {
    track (name) {
      if (this.productId) {
        Analytics.trackEvent('Download', {
          category: 'ProductAction: Download Document',
          label: name,
          wineryId: this.wineryId,
          productId: this.productId
        })
      } else if (this.wineryId) {
        Analytics.trackEvent('Download', {
          category: 'WineryAction: Download Document',
          label: name,
          wineryId: this.wineryId
        })
      }
      if (this.regionId) {
        Analytics.trackEvent('Download', {
          category: 'RegionAction: Download Document',
          label: name,
          regionId: this.regionId
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
